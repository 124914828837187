<template>
  <el-main>提现账号</el-main>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
